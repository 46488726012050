// Prism themes

require('prismjs/plugins/command-line/prism-command-line.css')
//require("prismjs/themes/prism.css")
require('prismjs/themes/prism-okaidia.css')
//require("prismjs/themes/prism-tomorrow.css")
//require("prismjs/themes/prism-solarizedlight.css")
//require("prismjs/themes/prism-coy.css")
//require("prismjs/themes/prism-dark.css")
//require("prismjs/themes/prism-twilight.css")
//require("prismjs/themes/prism-funky.css")
