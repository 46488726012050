module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/Users/admin/Documents/work/fe_doc/src/layouts/index.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"environment":"production","enabled":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"defaultLayouts":{"posts":"/Users/admin/Documents/work/fe_doc/src/templates/PostTemplate.js","page":"/Users/admin/Documents/work/fe_doc/src/templates/PageTemplate.js","tags":"/Users/admin/Documents/work/fe_doc/src/templates/TagTemplate.js"},"gatsbyRemarkPlugins":["gatsby-remark-autolink-headers",{"resolve":"gatsby-remark-images","options":{"maxWidth":800}},{"resolve":"gatsby-remark-embedder","options":{},"services":{}},"gatsby-plugin-sharp",{"resolve":"gatsby-remark-images","options":{"maxWidth":800,"backgroundColor":"transparent"}},{"resolve":"gatsby-remark-responsive-iframe","options":{"wrapperStyle":"margin-bottom: 2em"}},{"resolve":"gatsby-remark-prismjs","options":{"inlineCodeMarker":null,"aliases":{},"classPrefix":"language-","showLineNumbers":false,"noInlineHighlight":false,"prompt":{"user":"root","host":"localhost","global":false}}},"gatsby-remark-copy-linked-files","gatsby-remark-smartypants",{"resolve":"gatsby-remark-emojis","options":{"active":true,"class":"emoji-icon","size":64,"styles":{"display":"inline","margin":"0","margin-top":"1px","position":"relative","top":"5px","width":"25px"}}}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.chrisotto.dev"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"AFE Documentation","short_name":"AFE'Doc","start_url":"/","background_color":"#6b37bf","theme_color":"#6b37bf","display":"standalone","icons":[{"sizes":"144x144","src":"icons/icon.png?t=1626849639335","type":"image/png","purpose":"any"}],"cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
