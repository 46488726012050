module.exports = {
  siteTitle: 'AFE | Afanti\'s Front-End Developers', // <title>
  shortSiteTitle: 'Afanti\'s Front-End Developers', // <title> ending for posts and pages
  siteDescription: 'Afanti\'s Front-End Developers',
  siteUrl: 'https://fe-doc.frontend.cloud.afanticar.net',
  siteLanguage: 'en',

  /* author */
  authorName: 'AFE',
  authorTwitterAccount: 'AFE',

  /* info */
  headerTitle: 'AFE',
  headerSubTitle: 'Afanti\'s Front-End Developers',

  /* manifest.json */
  manifestName: 'AFE Documentation',
  manifestShortName: 'AFE\'Doc', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#6b37bf',
  manifestThemeColor: '#6b37bf',
  manifestDisplay: 'standalone',

  // gravatar
  gravatarImgMd5: '',

  // social
  authorSocialLinks: [
    { name: 'dev', url: 'https://dev.to/chrisotto' },
    { name: 'github', url: 'https://github.com/chrisotto6' },
    { name: 'instagram', url: 'https://instagram.com/wisco_cmo' },
    { name: 'linkedin', url: 'https://www.linkedin.com/in/ottochristopher/' },
    { name: 'twitter', url: 'https://twitter.com/wisco_cmo' },
  ],
}
